.btn-advanced-search{
  font-family: 'siyuan-me';
  color: #fff;
  border: 0;
  cursor: pointer;
  height: 46px;
  margin-left: 30px;
  background-image: linear-gradient(to right, #2A61E9, #40AAF1);
  padding: 0 25px 0 17px;
  border-radius: 2px;
}

.advanced-search-container{
  position: fixed;
  width: 100%;
  height: 490px;
  z-index: 99;
  background-color: #EEF1F6;
  top: -490px;
  left: 0;
  transition: all 0.5s;
  box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.1);
  &.open{
    top: 0;
  }
  .advanced-search-title{
    height: 60px;
    background-color: #19214F;
    padding: 15px 80px;
    line-height: 30px;
    font-size: 20px;
    color: #fff;
    img{
      width: 20px;
      vertical-align: top;
      margin-right: 20px;
      margin-top: 5px;
    }

    .advanced-search-close{
      margin-right: 0;
      float: right;
    }
  }

  .advanced-search-items{
    list-style: none;
    li{
      margin-top: 20px;
      overflow: hidden;
      font-family: 'siyuan',"微软雅黑";
      .advanced-search-label{
        width: 30%;
        height: 36px;
        line-height: 36px;
        text-align: right;
        padding-left: 37px;
        float: left;
        font-size: 16px;
        color: #333;
      }

      .advanced-search-input{
        width: 38%;
        height: 36px;
        padding-left: 10px;
        margin-left: 40px;
        float: left;
        border: 0;
        outline: 0;
      }

      .advanced-search-suffix{
        margin-left: 20px;
        line-height: 36px;
        text-align: right;
        font-size: 12px;
        color: #999;
        float: left;
      }

      button{
        height: 46px;
        padding: 0 25px 0 17px;
        margin-right: 97px;
        background-image: linear-gradient(to right, #2A61E9, #40AAF1);
        float: right;
        color: #fff;
        border: 0;
        cursor: pointer;
        border-radius: 2px;
        font-size: 13px;
      }
    }
  }
}