.mesh-class-wrapper{
  width: 250px;
  flex: 0 0 250px;
  border-left: 2px solid #EEF3FF;
  border-right: 2px solid #EEF3FF;
  overflow: auto;
  height: calc(100vh - 90px);

  .mesh-class-title{
    width: 100%;
    height: 64px;
    line-height: 64px;
    padding: 8px 0 0 20px;
    font-size: 14px;
    font-weight: 600;
    font-family: 'siyuan-bo';
  }
  ul{
    list-style: none;
    padding: 0;
    margin: 0;
    li{
      padding-left: 25px;
      background-color: transparent;
      height: 50px;
      line-height: 50px;
      font-size: 14px;
      font-family: 'siyuan-me';
      cursor: pointer;
      &:hover{
        background-color: #F5F6F8;
      }
      &.mesh-checked{
        background-color: #EEF3FF;
        border-left: 2px solid #2A61E9;
        padding-left: 23px;
      }
    }
  }
}