.result-item{
  width: 100%;
  padding: 16px 0;
  border-bottom: 1px solid #EEF1F6;

  .result-chemical-item{
    .chemical-item-title{
      font-size: 16px;
      font-weight: bold;
      height: 30px;
    }
    a + a{
      margin-left: 6px;
    }
  }

  .result-author-item{
    display: flex;
    position: relative;
    &.result-author-rank-item{
      img{
        height: 80px;
      }
      .author-item-title{
        font-size: 14px !important;
        height: 30px !important;
      }
    }
    img{
      flex: 0 0 80px;
      width: 80px;
      height: 100px;
    }
    >span{
      position: absolute;
      top: 0;
      left: 0;
      background-color: #2A61E9;
      padding: 1px 5px;
      font-size: 12px;
      color: #fff;
      border-radius: 2px;
    }
    >div{
      flex: 1;
      margin-left: 20px;
      .author-item-title{
        font-size: 20px;
        font-weight: 600;
        height: 50px;
        a{
          color: #333;
        }
      }
      ul{
        list-style: none;
        margin: 0;
        li{
          display: inline-block;
          background-color: #EEF3FF;
          padding: 2px 8px;
          margin-right: 10px;
          font-size: 12px;
          color: #333;
          span{
            color: #2A61E9;
          }
        }
      }
      .author-item-org{
        margin-top: 10px;
        font-size: 12px;
        color: #333;
        img{
          width: auto;
          height: 14px;
          margin-right: 10px;
          position: relative;
          top: -2px;
        }
      }
    }
  }


  .result-other-item{
    .other-item-title{
      display: block;
      color: #333;
      text-decoration: none;
      font-family: 'siyuan-me';
      font-size: 16px;
      span{
        background-color: #2A61E9;
        padding: 3px 12px;
        margin-right: 10px;
        font-weight: 600;
        font-size: 12px;
        color: #fff;
        border-radius: 2px;
      }
    }
    .other-item-row{
      >img{
        width: 13px;
        margin-right: 15px;
        margin-top: -2px;
      }
      ul{
        display: inline;
        list-style: none;
        li{
          display: inline;
          a{
            font-size: 14px;
            color: #2a61e9;
            cursor: pointer;
          }
          & + li{
            margin-left: 10px;
          }
          &.item-arrow-wrapper{
            img{
              width: 12px;
              height: 6px;
              margin: 4px 10px 0;
              vertical-align: text-top;
              cursor: pointer;
              transition: all .2s;
            }
            &.open{
              img{
                transform: rotate(180deg);
              }
            }
          }
        }
      }
    }
  }

  .result-item-checkbox{
    margin-right: 15px;
    .ant-checkbox-inner{
      border-color: #2A61E9;
    }
  }

  .result-org-item{
    .org-item-title{
      font-size: 16px;
      cursor: pointer;
    }
    ul{
      list-style: none;
      margin: 6px 0 0;
      li{
        height: 24px;
        line-height: 24px;
        background-color: #EEF3FF;
        margin-right: 10px;
        padding: 0 10px;
        color: #333;
        font-size: 12px;
        display: inline-block;
        span{
          color: #2A61E9;
          margin-left: 5px;
        }
      }
    }
  }
}