
.header-container{
  height: 90px;
  min-width: 1050px;
  background-color: #19214F;
  .header-logo{
    display: inline-block;
    height: 90px;
    line-height: 90px;
    margin-left: calc(25% - 350px);
    img{
      width: 60px;
      margin-right: 10px;
      margin-top: -8px;
    }
    span{
      font-size: 20px;
      color: #fff;
    }
  }
  .header-search-box{
    position: absolute;
    top: 20px;
    left: calc(50% - 535px);
    margin: 0;
    .search-input{
      width: 310px !important;
    }
  }

  .header-right{
    float: right;
    margin-top: 34px;
    margin-right: 25px;
    >span{
      border-left: 1px solid white;
      padding: 0 14px;
      color: #B8BFC7;
      font-size: 12px;
    }
  }
}