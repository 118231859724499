@font-face{
  font-family: 'siyuan-bo';
  src: url('./fonts/SourceHanSansCN-Bold.eot');
  src:
    url('./fonts/SourceHanSansCN-Bold.eot') format('embedded-opentype'),
    url('./fonts/SourceHanSansCN-Bold.woff2') format('woff2'),
    url('./fonts/SourceHanSansCN-Bold.woff') format('woff'),
    url('./fonts/SourceHanSansCN-Bold.ttf') format('truetype'),
    url('./fonts/SourceHanSansCN-Bold.svg') format('svg');
  font-weight: normal;
  font-style: normal;
}
@font-face{
  font-family: 'siyuan';
  src: url('./fonts/SourceHanSansCN-Regular.eot');
  src:
    url('./fonts/SourceHanSansCN-Regular.eot?#font-spider') format('embedded-opentype'),
    url('./fonts/SourceHanSansCN-Regular.woff2') format('woff2'),
    url('./fonts/SourceHanSansCN-Regular.woff') format('woff'),
    url('./fonts/SourceHanSansCN-Regular.ttf') format('truetype'),
    url('./fonts/SourceHanSansCN-Regular.svg') format('svg');
  font-weight: normal;
  font-style: normal;
}
@font-face{
  font-family: 'siyuan-me';
  src: url('./fonts/SourceHanSansCN-Medium.eot');
  src:
    url('./fonts/SourceHanSansCN-Medium.eot?#font-spider') format('embedded-opentype'),
    url('./fonts/SourceHanSansCN-Medium.woff2') format('woff2'),
    url('./fonts/SourceHanSansCN-Medium.woff') format('woff'),
    url('./fonts/SourceHanSansCN-Medium.ttf') format('truetype'),
    url('./fonts/SourceHanSansCN-Medium.svg') format('svg');
  font-weight: normal;
  font-style: normal;
}


* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'siyuan',"微软雅黑"
}

body {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  min-width: 1170px;
}

input::-webkit-input-placeholder {
  /* placeholder颜色  */
  color: #B8BFC7;
  /* placeholder字体大小  */
  font-size: 14px;
  /* placeholder位置  */
}

@import "~antd/dist/antd.css";

.spin-container {
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}


.home-container{
  .home-header-container{
    min-width: 1050px;
    height: 460px;
    padding-top: 120px;
    background-color: #19214F;
    position: relative;

    .batch-search-wrapper{
      position: absolute;
      top: 30px;
      right: 40px;
    }

    .logo-wrapper{
      padding: 30px 0 59px;
      display: flex;
      justify-content: center;
      align-items: center;
      img{
        width: 120px;
        height: 60px;
        margin-right: 10px;
      }
      span{
        font-size: 57px;
        color: #fff;
        line-height: 60px;
        font-family: 'siyuan',"微软雅黑";
      }
    }
  }


  .home-ranking-container{
    width: 1000px;
    height: 450px;
    border-bottom: 1px solid #eef1f6;
    margin: 0 auto;
    padding-top: 36px;
    .home-ranking-title{
      font-size: 24px;
      font-weight: 600;
      font-family: 'siyuan',"微软雅黑";
      padding-left: 32px;
    }

    .home-ranking-list{
      list-style: none;
      margin-bottom: 0;
      margin-top: -13px;
      li{
        width: 460px;
        height: 100px;
        line-height: 100px;
        margin: 40px 0 0 30px;
        background: #F5F6F8;
        box-shadow: 0px 5px 10px rgba(0,0,0,0.06);
        font-size: 20px;
        font-weight: 600;
        float: left;
        cursor: pointer;
        .home-ranking-img{
          float: left;
          width: 156px;
          height: 100px;
          padding: 20px 38px;
          margin-right: 60px;
          background-image: linear-gradient(to right, #2A61E9, #40AAF1);
          img{
            width: 80px;
            height: 60px;
            vertical-align: top;
          }
        }
      }
    }
  }
  .home-footer-wrapper{
    width: 200px;
    height: 16px;
    line-height: 16px;
    background-color: white;
    margin: -12px auto 0;
    color: #999999;
    text-align: center;
    font-size: 12px;
  }
}

.content-container{
  width: 1070px;
  margin: 0 auto;
}

.search-content-wrapper{
  padding-top: 30px;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  color: #000;
  font-family: 'siyuan-bo';
  span{
    color: #2A61E9;
  }
}

.search-page-wrapper{
  padding: 20px 0;
  text-align: center;
}

.search-spin-wrapper{
  .ant-spin{
    position: fixed !important;
    top: 90px !important;
  }
}

.rank-content-box{
  padding: 16px;
  border-bottom: 1px solid #EEF1F6;
  .rank-title-wrapper{
    font-size: 16px;
    cursor: pointer;
    .rank-rank{
      background-color: #2A61E9;
      padding: 1px 7px 0;
      margin-right: 10px;
      width: 30px;
      height: 17px;
      line-height: 17px;
      text-align: center;
      font-size: 12px;
      display: inline-block;
      color: #fff;
      font-weight: bold;
      vertical-align: text-top;
    }
  }
  ul{
    list-style: none;
    margin: 6px 0 0;
    li{
      height: 24px;
      line-height: 24px;
      background-color: #EEF3FF;
      margin-right: 10px;
      padding: 0 10px;
      color: #333;
      font-size: 12px;
      display: inline-block;
      span{
        color: #2A61E9;
        margin-left: 5px;
      }
    }
  }
}

.rank-content-container{
  display: flex;
  .rank-content-wrapper{
    flex: 1;
    margin-left: 60px;
    .rank-paper-title, .rank-author-title{
      border-bottom: 1px solid #EEF1F6;
      width: 100%;
      height: 50px;
      line-height: 50px;
      font-size: 16px;
      font-weight: 600;
      font-family: 'siyuan-bo';
    }
    .rank-author-title{
      border-bottom: none;
    }
    .rank-author-desc{
      font-family: 'siyuan-me';
      font-size: 14px;
      color: #333;
    }
  }
}


.author-info-container{
  display: flex;
  padding: 30px 0 20px;
  border-bottom: 2px solid #EEF1F6;
  height: 410px;
  .author-info-avatar{
    flex: 0 0 160px;
  }
  .author-info-base{
    flex: 1;
    padding: 0 30px;

    .info-base-name{
      height: 50px;
      font-size: 20px;
      font-weight: 600;
      color: #333;
    }
    ul{
      list-style: none;
      margin: 0;
      li{
        display: inline-block;
        background-color: #EEF3FF;
        padding: 4px 8px;
        margin-right: 10px;
        font-size: 12px;
        color: #333;
        span{
          color: #2A61E9;
        }
      }
    }
    .info-base-org{
      margin-top: 10px;
      font-size: 12px;
      color: #333;
      img{
        margin-right: 10px;
      }
    }
  }
  .author-info-co{
    flex: 0 0 360px;
    border: 2px solid #EEF3FF;
    .author-co_head{
      background-color: #EEF3FF;
      height: 50px;
      line-height: 50px;
      font-weight: 600;
      font-size: 16px;
      color: #333;
      img{
        margin: 0 18px;
        position: relative;
        top: -2px;
      }
    }

    ul{
      list-style: none;
      margin: 0;
      padding: 0 10px;
      font-size: 12px;
      font-family: 'siyuan',"微软雅黑";
      color: #333;
      li{
        margin-top: 5px;
        .author-co_name{
          width: 270px;
          white-space: nowrap;
          text-overflow: ellipsis;
          overflow: hidden;
          cursor: pointer;
          &:hover{
            color: #2A61E9;
          }
        }
        .author-co_number{
          border: 1px solid #2A61E9;
          line-height: 14px;
          padding: 0 6px;
          color: #2A61E9;
          border-radius: 2px;
          float: right;
        }
      }
    }
  }
}

.detail-content-container{
  height: calc(100% - 410px);
  display: flex;
  padding-top: 14px;
  .detail-menu-container{
    flex: 0 0 160px;
    height: 100%;
    border-right: 1px solid #EEF3FF;
    border-left: 1px solid #EEF3FF;
    .detail-menu-item{
      padding-left: 20px;
      height: 50px;
      line-height: 50px;
      margin-left: -2px;
      font-size: 16px;
      color: #333;
      cursor: pointer;
      &.active{
        background-color: #EEF3FF;
        border-left: 2px solid #2A61E9;
        padding-left: 18px;
        cursor: auto;
      }
    }
    .detail-info-submit{
      color: #b8bfc7;
      margin-top: 10px;
      cursor: pointer;
      font-size: 12px;
    }
  }
  .detail-content-wrapper{
    flex: 1;
    height: 100%;
    padding-left: 30px;
    &.detail-content-author{
      .detail-papers-wrapper{
        display: none;
      }
      .detail-author-wrapper{
        display: flex;
      }
    }
  }
}

.detail-info-popover{
  .ant-popover-inner{
    background-color: #F5F6F8;
  }
  .ant-popover-inner-content{
    padding: 30px 20px;
  }
}

.detail-papers-wrapper{
  display: flex;
  flex-direction: column;
  height: 100%;   
  .detail-papers-years{
    flex: 0 0 auto;
    border-bottom: 1px solid #EEF3FF;
    transition: all 0.2s;
    display: flex;
    ul{
      flex: 1;
      list-style: none;
      margin: 0;
      transition: all 0.2s;
      li{
        display: inline-block;
        width: 50px;
        height: 34px;
        padding: 3px 0;
        font-size: 12px;
        font-family: 'siyuan-me';
        color: #000;
        span{
          padding: 2px 5px;
          cursor: pointer;
        }
        &.active{
          span{
            background-color: #EEF3FF;
            color: #2A61E9;
          }
        }
      }
    }
    >div{
      flex: 0 0 30px;
      text-align: center;
      &.open{
        img{
          transform: rotate(0deg);
        }
      }
      img{
        width: 12px;
        transition: all 0.2s;
        transform: rotate(180deg);
        cursor: pointer;
      }
    }
  }
  .detail-papers-content{
    flex: 1 1 auto;
    overflow: auto;
  }
}

.org-info-container{
  display: flex;
  padding: 30px 0 10px;
  border-bottom: 2px solid #EEF1F6;
  height: 290px;
  .org-info-avatar{
    flex: 0 0 160px;
  }
  .org-info-base{
    flex: 0 0 360px;
    padding: 0 30px;
    .info-base-name{
      height: 50px;
      font-size: 20px;
      font-weight: 600;
      margin-bottom: 20px;
      color: #333;
    }
    ul{
      list-style: none;
      margin: 0;
      li{
        display: inline-block;
        background-color: #EEF3FF;
        padding: 4px 8px;
        margin-right: 10px;
        margin-bottom: 10px;
        font-size: 12px;
        color: #333;
        span{
          color: #2A61E9;
        }
      }
    }
  }

  .org-info-chart{
    flex: 1;
    border-left: 1px solid #EEF1F6;
    padding-left: 60px;
  }
}

.detail-author-wrapper{
  display: none;
  flex-direction: column;
  height: 100%;
  .detail-author-content{
    height: calc(100% - 56px);
    overflow: auto;
  }
}

.btn-generator-citation{
  margin-top: 16px;
  background-color: #fff;
  color: #2a61e9;
  border: 1px solid #2a61e9;
  cursor: pointer;
  width: 152px;
  height: 26px;
  font-size: 13px;
  &.btn-download{
    background-color: #2a61e9;
    color: white;
    border: 0;
  }
}