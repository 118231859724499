.search-container{
  width: 960px;
  margin: 0 calc(50% - 540px) 0 auto;
  display: flex;
  height: 46px;
  .search-wrapper{
    background-color: #fff;
    .search-select{
      width: 128px;
      user-select: none;
      .ant-select-selector{
        border: 0;
        height: 46px;
        padding: 12px 10px;
        border-color: transparent !important;
        box-shadow: none !important;
        .ant-select-selection-item{
          line-height: 22px;
        }
      }

      .ant-select-arrow{
        width: 0;
        height: 0;
        margin-top: -3px;
        border: 6px solid transparent;
        border-color: black transparent transparent transparent;
        span{
          display: none;
        }
      }

      &.ant-select-open{
        .ant-select-arrow{
          margin-top: -8px;
          border-color: transparent transparent black transparent;
        }
      }
    }

    .search-input{
      width: 580px;
      height: 30px;
      padding-left: 20px;
      border: 0;
      outline: none;
      border-left: 1px solid #19214F;
      font-family: 'siyuan-me';
    }
    button{
      width: 60px;
      height: 46px;
      line-height: 46px;
      color: #fff;
      background-color: #2A61E9;
      border: 0;
      cursor: pointer;
    }
  }
}