.batch-search-wrapper{
  border: 0;
  background-color: transparent;
  padding: 0 15px;
  display: inline-block;
  color: #fff;
  font-size: 14px;
  span{
    cursor: pointer;
  }
}

.batch-search-modal{
  $modalBg: #EEF1F6;
  .ant-modal-header{
    padding: 20px;
    border: none;
    background-color: $modalBg;
    .ant-modal-title{
      img{
        width: 16px;
        margin-right: 14px;
      }
      span{
        font-size: 16px;
        font-family: 'siyuan-bo';
      }
    }
  }

  .ant-modal-body{
    background-color: $modalBg;
    padding: 0 20px;
  }

  .ant-modal-footer{
    background-color: $modalBg;
    padding: 14px 20px 20px;
    button{
      width: 70px;
      height: 24px;
      line-height: 24px;
      margin-left: 20px;
      border: 0;
      padding: 0;
    }
    .batch-modal-ok{
      color: #fff;
      background-image: linear-gradient(to right, #2A61E9, #40AAF1);
    }

    .batch-modal-cancel{
      border: 1px solid #B8BFC7;
      background-color: #EEF1F6;
    }
  }

  .ant-modal-close-x{
    font-size: 24px;
  }
}

.batch-search-form{
  padding-top: 7px;
  .batch-search-row{
    display: flex;
    line-height: 30px;
    color: #333;
    width: 100%;
    & + .batch-search-row{
      margin-top: 20px;
      height: 90px;
      color: #999;
    }

    .batch-search-title{
      flex: 0 0 70px;
      font-size: 16px;
    }

    .batch-search-content{
      flex: 1;
      & > div{
        position: relative;
        height: 30px;
        background-color: white;
        padding-left: 10px;
        font-size: 14px;
        cursor: pointer;
        border-radius: 2px;
        color: #b8bfc7;
      }
      input{
        width: 100%;
        opacity: 0;
        position: absolute;
        top: 0;
        right: 0;
        left: 0;
        bottom: 0;
        cursor: pointer;
      }
      span{
        color: #333;
      }
    }

    p{
      font-size: 12px;
      padding-left: 10px;
      line-height: 15px;
      margin-bottom: 0;
    }

  }
}