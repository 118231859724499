.search-sort-container{
  font-size: 14px;
  color: #999;
  height: 40px;
  margin-top: 16px;
  line-height: 40px;
  background-color: #F5F6F8;
  display: flex;
  font-family: 'siyuan',"微软雅黑";
  .search-sort-title{
    padding: 0 10px;
  }
  ul{
    list-style: none;
    li{
      display: inline-block;
      height: 20px;
      line-height: 20px;
      padding: 0 15px;
      margin-right: 30px;
      cursor: pointer;
      border-radius: 2px;
      &.checked{
        background-color: white;
        color: #333;
      }
    }
  }
}